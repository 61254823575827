.alert-info {
    transition: all 0.5s;
    background-color: #ff9f9f;
    padding: 10px 0;
    position: absolute;
    opacity: 0.8;
    display: block;
    top: 40px;
    width: 100%;
    z-index: 99;
    left: 0;

    &.hide {
        opacity: 0;
        display: none;
    }

    .fa {
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 20px;
        cursor: pointer;
    }

}