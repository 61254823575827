.prompt-api {
    background-color: #353535;
    opacity: 0.8;
    width: 100%;
    position: absolute;
    height: 100vh;
    z-index: 99;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;

    .modal {
        background-color: #fff;
        width: 500px;
        padding: 20px;
        border-radius: 5px;

        .modal-header {
            font-weight: 800;
            font-size: large;
            border-bottom: 1px solid #e6e6e6;
            padding-bottom: 15px;
        }

        .input {
            input {
                display: block;
                width: 100%;
                padding: 10px 5px;
                border: 1px solid #e6e6e6;
            }
        }

        button {
            cursor: pointer;
            font-weight: 900;
            margin-top: 10px;
            border: none;
            font-size: 15px;
            padding: 5px 15px;
            border-radius: 5px;
        }
    }
}