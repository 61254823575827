.card-layout {
    display: flex;

    /* Put a card in the next row when previous cards take all width */
    flex-wrap: wrap;

    margin-left: -0.25rem;
    margin-right: -0.25rem;


    .card-layout__item {
        /* There will be 3 cards per row */
        flex-basis: 33.33333%;

        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

.row {
    display: flex;

    margin-left: -0.25rem;
    margin-right: -0.25rem;


    .cell {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }

    .cell--fill {
        flex: 1;
    }

    /* Cell with given width */
    .cell--2 {
        flex: 0 0 50%;
    }

    .cell--3 {
        flex: 0 0 33.3333333%;
    }

    .cell--4 {
        flex: 0 0 25%;
    }
}

.clearfix:before,
.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}

.clearfix:after {
    clear: both;
}

/* IE < 8 */
.clearfix {
    zoom: 1;
}