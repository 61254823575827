//
// Chat.scss
//

.chat-leftsidebar {
  @media (min-width: 992px) {
    min-width: 260px;
  }

  .chat-leftsidebar-nav {
    .nav {
      background-color: 'green';
    }

    .tab-content {
      min-height: 488px;
    }
  }
}

.chat-noti-dropdown {
  &.active {
    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      right: 0;
    }
  }

  .btn {
    padding: 6px;
    box-shadow: none;
    font-size: 20px;
  }
}

.chat-search-box {
  .form-control {
    border: 0;
  }
}

.chat-list {
  margin: 0;

  li {
    &.active {
      a {
        background-color: $card-bg;
        border-color: transparent;
        box-shadow: $box-shadow;
      }
    }

    a {
      display: block;
      padding: 14px 16px;
      color: $gray-600;
      transition: all 0.4s;
      border-top: 1px solid $border-color;
      border-radius: 4px;

      &:hover {
        background-color: $card-bg;
        border-color: transparent;
        box-shadow: $box-shadow;
      }
    }
  }
}

.message-bubble-container {
  position: relative;

  svg.icon {
    width: 20px;
    height: 20px;
  }

  .cs-message {
    overflow: visible;

    &.message-bubble-image {
      .cs-message__content {
        padding: 0;
      }
    }

    .cs-message__html-content {
      white-space: normal;

      p {
        padding: 0;
        line-height: 1.5em;
        margin: 5px 0 0;
      }

      p:nth-child(1) {
        margin-top: 0;
      }
    }

    .image-content {
      .loading {
        width: 250px;
      }

      img {
        width: 100%;
        max-width: 250px;
      }
    }
  }

  .cs-message:before,
  .cs-message:after {
    content: '.';
    display: block;
    height: 0;
    overflow: hidden;
  }

  .cs-message:after {
    clear: both;
  }

  &.error {
    .cs-message__content {
      background-color: $gray-500 !important;
      color: #444;
    }
  }

  .bubble-error {
    position: absolute;
    top: 5px;
    display: block;
    width: 20px;
    height: 20px;
    left: -30px;
    opacity: 0.8;
    text-align: center;
    font-weight: 400;
    color: #939090;
    cursor: pointer;
  }
}

.cs-message__footer {
  .fa {
    margin-right: 10px;
    color: $gray-500;
  }

  .fa.fa-check-double {
    color: green;
  }
}

.user-chat-nav {
  .dropdown {
    .nav-btn {
      height: 40px;
      width: 40px;
      line-height: 40px;
      box-shadow: none;
      padding: 0;
      font-size: 16px;
      background-color: $light;
      border-radius: 50%;
    }

    .dropdown-menu {
      box-shadow: $box-shadow;
      border: 1px solid $border-color;
    }
  }
}

.cs-message-separator {
  background-color: transparent !important;
}

.cs-main-container {
  border: none;

  .cs-chat-container {
    z-index: 10;
  }
}

.chat-conversation {
  li {
    clear: both;
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;

    .title {
      background-color: $card-bg;
      position: relative;
      z-index: 1;
      padding: 6px 24px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }

    .badge {
      font-size: 12px;
    }
  }

  .conversation-list {
    margin-bottom: 24px;
    display: inline-block;
    position: relative;

    .ctext-wrap {
      padding: 12px 24px;
      background-color: rgba($primary, 0.1);
      border-radius: 8px 8px 8px 0px;
      overflow: hidden;
      font-size: 16px;
      position: relative;

      .conversation-name {
        font-weight: $font-weight-semibold;
        font-family: 'Poppins', sans-serif !important;
        color: $primary;
        margin-bottom: 4px;
      }
    }

    .dropdown {
      float: right;

      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: $gray-600;

        @media (max-width: 575.98px) {
          display: none;
        }
      }

      .dropdown-menu {
        box-shadow: $box-shadow;
        border: 1px solid $border-color;
      }
    }

    .chat-time {
      font-size: 12px;
    }
  }

  .right {
    .conversation-list {
      float: right;

      .ctext-wrap {
        background-color: $light;
        text-align: right;
        border-radius: 8px 8px 0px 8px;
      }

      .dropdown {
        float: left;
      }

      &.last-chat {
        .conversation-list {
          &:before {
            right: 0;
            left: auto;
          }
        }
      }
    }
  }

  .last-chat {
    .conversation-list {
      &:before {
        content: '\F0009';
        font-family: $font-family-sans-serif;
        position: absolute;
        color: $primary;
        right: 0;
        bottom: 0;
        font-size: 16px;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }
  }
}

.cs-conversation-header {
  background-color: #fff;
  position: relative;
  font-family: $font-family-sans-serif;

  .websocket-status.connected {
    opacity: 0;
  }

  .websocket-status.failure {
    opacity: 0.8;
    background-color: rgb(247 185 46 / 65%);
  }

  .websocket-status {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #75ffaf;
    display: block;
    padding: 10px 0;
    border-radius: 0;
    opacity: 0.8;
    font-weight: 700;
    text-align: center;
    transition: all 0.4s;

    &.disconnected {
      height: 100vh;
      background-color: #1e1e1e;
      color: #f7b233;

      small {
        font-weight: 400;
      }

      .button {
        cursor: pointer;
        padding: 10px;
        text-align: center;
        border: 1px solid $gray-500;
        width: 120px;
        background-color: $gray-100;
        margin: 0 auto;
        border-radius: 5px;
        opacity: 1;
        color: #1e1e1e;

        .fa {
          margin: 0 10px;
        }
      }
    }

    .fa {
      margin-right: 5px;
    }
  }
}

.chat-input-section {
  border-top: 1px solid $border-color;
}

.chat-input {
  border-radius: 30px;
  background-color: $light !important;
  border-color: $light !important;
  padding-right: 120px;
  font-size: 14px;
}

.chat-input-links {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  li {
    a {
      font-size: 16px;
      line-height: 36px;
      padding: 0px 4px;
      display: inline-block;
    }
  }
}

.chat-send {
  @media (max-width: 575.98px) {
    min-width: auto;
  }
}

.cs-message-group {
  .cs-message__html-content a {
    color: #4444ff;
  }
}

.live-chat {
  border-radius: 15px;
  overflow: hidden;
  padding: 15px 15px;
  height: 100%;
  max-width: 100%;
  width: 540px;
  pointer-events: auto;
  box-shadow: rgb(0 0 0 / 20%) -2px 7px 10px 0px;
  background-color: rgb(245 245 245);
  z-index: 10;
  opacity: 1;
  transform: translate(0px, 0px);
  transition: transform 300ms ease 0s, opacity 300ms ease 0s;
  visibility: visible;
  font-family: -apple-system, BlinkMacSystemFont, 'Apple Color Emoji',
    'Segoe UI', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Roboto', Helvetica, Arial,
    sans-serif;

  .live-chat-form-register {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
    padding: 15px 0;
    background-color: #f2f2f254;
    color: #000;

    .tagline {
      color: #000;
      padding: 0 15px;
    }

    .login-form-container {
      max-width: 100%;
      padding: 20px;

      border-radius: 5px;
      display: block;

      .annotation.required {
        color: rgb(240, 25, 25);
      }

      form {
        display: block;
        width: 100%;
        margin: 0 auto;
      }

      textarea {
        height: 60px;
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 20px;
    }

    .form-group {
      margin-bottom: 15px;
      display: flex;
      position: relative;
      width: 100%;
      flex-direction: column;
    }

    label {
      display: block;
      font-weight: 400;
      margin-bottom: 10px;
      text-align: left;
      font-size: 14px;
    }

    input,
    textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }

    button {
      display: block;
      width: 100%;
      padding: 10px;
      background-color: #29ccb1;
      border: 1px solid #29ccb1;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &[disabled] {
        background-color: #c3c3c3;

        &:hover {
          background-color: #c3c3c3;
        }
      }
    }

    button:hover {
      background-color: #29ccb1;
    }
  }

  .container-fluid {
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: 400;
    height: 100%;
    text-size-adjust: 100%;
  }

  .cs-conversation-header__avatar {
    box-sizing: border-box;
    width: 70px;
    height: 20px;
    margin-right: 1em;
    order: 2;
  }

  .cs-conversation {
    font-family: $font-family-sans-serif !important;
  }

  .cs-conversation-header {
    padding: 17px 35px 10px;
    font-family: $font-family-sans-serif !important;

    .cs-avatar img,
    .cs-avatar.cs-avatar--md {
      border-radius: 50%;
      width: 70px;
      height: auto;
    }
  }

  .register-now {
    display: block;
    position: absolute;
    width: 100%;
    padding: 10px 0;
    bottom: 14px;
    z-index: 99;
    left: 0;
    cursor: pointer;

    .text {
      border-radius: 5px;
      background: #29ccb1;
      display: block;
      color: #fff;
      padding: 10px 15px;
      text-align: left;
      position: relative;
      margin: 0 30px;

      .icon {
        width: 15px;
        position: absolute;
        right: 20px;
        bottom: 12px;
      }
    }
  }

  .cs-chat-container
    .cs-message-input
    .cs-message-input__content-editor-wrapper:first-child {
    margin-left: 0;
  }

  .cs-message-input__content-editor,
  .cs-message-input__content-editor-wrapper,
  .cs-message-input__content-editor-container {
    background-color: #ffffff;
    color: #000;
  }

  .chat-loader {
    background: #457be6;
    width: 100%;
    color: #fff;
    padding: 10px 0;
    position: absolute;
    bottom: -42px;
    left: 0;
    opacity: 0.8;
    display: flex;
    justify-items: center;
    justify-content: center;
  }
}

.cs-chat-container {
  width: 100%;
}

.cs-message-list {
  text-align: left;
  padding: 15px 15px 0;
}

.chat-conversation .conversation-list span.highlight-text {
  background: #71f19385;
  padding: 2px;
  color: #5d5d5d;
  top: 15px;
  left: -15px;
  font-size: 9px;
  position: relative;
  height: 100px;
}

.chat-conversation .conversation-list span.highlight-text .score {
  font-size: 10px;
  font-weight: bold;
  margin-left: 5px;
}

.conversation-list .message-wrap {
  min-height: 79px;
  border-top: 1px solid #bcbbbb;
  padding-top: 14px;
}

.conversation-list .message-wrap > p {
  min-height: 60px;
}

span.highlight {
  background: #80fe98;
}

.conversation-list .sentiment-wrapper,
.conversation-list .intent-wrapper {
  /* position: absolute;
  right: 0;
  bottom: 0; */
  font-size: 11px;
}

.conversation-list .message-footer {
  height: 50px;
  border-top: 1px solid #e6e6e6;
  padding-top: 10px;
}

.conversation-list .feature-extraction {
  background-color: #28b342;
  padding: 3px;
  color: #fff;
  margin-bottom: 5px;
}

.cs-message-input__tools .cs-button--send {
  align-self: center;
}

#risa-layout-chat {
  .cs-main-container {
    .cs-chat-container .cs-message-input {
      border: solid 0px #d1dbe3;
      margin-top: auto;
      padding: 0.3em 0 0.3em 0em;
      color: rgba(0, 0, 0, 0.87);
      background-color: #fff;
      font-size: 16px;

      .cs-message-input__content-editor {
        background-color: #fff;
      }

      .cs-message-input__content-editor-wrapper {
        background-color: #fff;
        border: 1px solid #e6e6e6;
      }
    }

    .cs-chat-container,
    .live-chat .cs-message-list {
      background-color: transparent;
    }

    .cs-sidebar.cs-sidebar--left {
      max-width: 300px;

      .filter {
        display: flex;
        justify-content: space-evenly;
        color: #c2c7cf;
        font-size: 16px;
        margin: 10px 0;
        border-bottom: 1px solid #e6e6e6;
        border-top: 1px solid #e6e6e6;
        padding: 10px 0;

        :hover,
        .active {
          color: #54d6c1;
        }

        :hover {
          cursor: pointer;
        }
      }

      .cs-conversation.closed {
        background: #ececec;
      }

      .cs-conversation-list {
        li {
          margin-bottom: 1px;
        }
      }
    }

    .cs-conversation__name {
      box-sizing: border-box;
      color: #293448;
      font-size: 14px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }

    .cs-conversation__last-activity-time {
      box-sizing: border-box;
      margin: 0 0 0 auto;
      font-size: 12px;
      color: #c2c7cf;
    }
  }
}

.cs-message-group--incoming {
  .cs-message-group__messages .cs-message .cs-message__content {
    background-color: #eafaf7 !important;

    .generated_by_ai {
    }
  }

  .cs-message__footer .date {
    position: absolute;
    right: -60px;
    bottom: 5px;
  }
}

.cs-message-separator {
  &.exit_conversation {
    display: block;
    width: 250px;
    margin: 0 auto;
    padding: 5px 0;
  }

  &.exit_conversation::before,
  &.exit_conversation::after {
    width: 0;
    display: block;
    border: none;
    flex-grow: 0;
    background-color: transparent;
  }
}

.cs-message-group--outgoing {
  .cs-message-group__messages .cs-message .cs-message__content {
    background-color: #24b39b;
    color: #fff;
  }

  .cs-message__footer .date {
    position: absolute;
    left: -60px;
    bottom: 5px;
  }
}

.cs-chat-container .cs-message-input {
  text-align: left;
  border: none;
  margin: 15px;
  border-radius: 15px;
  padding: 0;
  // box-shadow: rgb(92 255 202 / 50%) 0px 12px 48px 4px;
  // border-color: #8bfdb999;
  // border-style: solid;
}

.cs-message-input__content-editor {
  // padding: 17px 10px;
  background-color: #fff;
  color: #000;
}

.cs-message-input__content-editor[data-placeholder]:empty:before {
  color: rgb(6 6 6 / 52%);
}

.skeleton-loader {
  transition: opacity 0.5s;
  opacity: 1;
}

.cs-message-input--disabled {
  .cs-message-input__content-editor {
    background-color: #fff;
  }

  .cs-message-input__content-editor-wrapper {
    background: #f6f6f6;
    color: #ebf3f2 !important;
    opacity: 0.5;
  }
}

.cs-message-input.cs-message-input--disabled,
.cs-message-input--disabled .cs-message-input__content-editor {
  background: #efefef;
}

.cs-message-input__content-editor-wrapper {
  padding: 0;
}

.skeleton-loader.hide {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.cs-message__content {
  ol {
    list-style: auto;
    padding-left: 15px;
  }

  font-size: 0.8em !important;
  font-family: "Poppins", Helvetica Neue,Segoe UI,Helvetica,Arial,sans-serif !important;
}


.bubble-product {
  width: 100%;
  min-width: 300px;
  display: block;
  padding: 8px;

  .row {
    padding: 8px;
  }

  .cs-message__content {
    padding: 15px !important;

    a {
      color: #047fde;
    }
  }

  .cell.cell--2.image-wrapper {
    max-height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .product-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #000;
  }

  .cs-message__content {
    border-radius: 0.7em 0.7em 0.7em !important;
  }

  .chip {
    font-size: 13px;
    color: #fff;
    background: #1cac93;
  }

  .image-product {
    width: 100%;
    max-height: 200px;
  }

  .name {
    font-family: 'Roboto', 'Times New Roman', Times, serif;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .price {
    font-size: 14px;
    margin-top: 10px;
    display: block;
  }

  .product-information {
    padding: 0 15px;
  }
}

.cs-message__html-content {
  line-height: 1.5em;
}

.cs-message__footer {
  span.font-size-10 {
    font-size: 10px;
  }
}

section.cs-message-group {
  margin-bottom: 20px;
}
