.dropdown-checkbox-costum {
  position: relative;
  min-width: 210px;

  .dropdown-checkbox {
    border-radius: 4px;
    border: 1px solid #a4abb8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;

    .dropdown-toggle {
      color: #7b8598;
      font-family: $font-family-sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .chevron-down {
      width: 24px;
      height: 24px;
    }
  }

  .dropdown-checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 8px 16px;
    position: absolute;
    background: #fff;
    border: 1px solid #a4abb8;
    top: 47px;
    left: 0px;
    right: 0px;
    border-radius: 4px;
    z-index: 10;

    .checkbox-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
      color: #293448;
      font-family: $font-family-sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      gap: 8px;

      .checkbox-input {
        border: 1px solid #a4abb8;
        width: 16px;
        height: 16px;
      }
    }
  }
}
