$container-max-width: 1200px;

.onboarding {
  background-color: #fdfdfd;
  font-family: -apple-system, BlinkMacSystemFont, 'Apple Color Emoji',
    'Segoe UI', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Roboto', Helvetica, Arial,
    sans-serif;
  height: 100vh;

  .alert-info {
    z-index: 10;
    transition: all 0.5s;
    background-color: #ff9f9f;
    padding: 10px 0;
    position: fixed;
    opacity: 1;
    width: 100%;
    display: block;
  }

  .container {
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 0;
    font-size: 1em;
    font-weight: 400;
    text-size-adjust: 100%;
    height: 100%;
  }

  .cs-main-container {
    z-index: 2;
    border: none;
  }

  .cs-main-container .cs-chat-container,
  .cs-message-list {
    background-color: #fdfdfd;
  }

  .cs-message-group--incoming
    .cs-message-group__messages
    .cs-message
    .cs-message__content {
    border-radius: 0 0.7em 0.7em 0.7em;
  }

  .cs-message-group--outgoing
    .cs-message-group__messages
    .cs-message
    .cs-message__content {
    border-radius: 0.7em 0 0.7em 0.7em;
    background-color: #98d9ce;
    color: #000000;
  }

  .cs-message-group--outgoing .cs-message-group__messages .cs-message {
    border-radius: 0.7em 0 0.7em 0.7em !important;
  }

  .cs-chat-container .cs-message-input {
    box-shadow: none;
    border: none;
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    background: transparent;
  }

  .cs-message-input__content-editor-wrapper {
    border-radius: 26px;
  }

  .cs-message-input__content-editor-container {
    border-radius: 26px;
  }

  .cs-message-input__content-editor {
    padding: 17px 26px;
  }

  .cs-button {
    background-color: #24b39b;
    padding: 12px 16px;
    border-radius: 26px;
    opacity: 1;

    svg {
      color: #fff;
    }
  }

  .onboarding-chat {
    padding: 4px;
    display: flex;
    flex-direction: column;

    button {
      margin-top: 8px;
      height: 30px;
      background-color: #24b39b;
      border: none;
      border-radius: 4px;
      color: #fff;
      width: 100px;
    }
  }

  .onboarding-select {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;

    .custom-dropdown {
      display: flex;
      flex-direction: column;
      // position: relative;

      .select {
        margin-top: 10px;
        position: relative;
        display: inline-block;
        width: 350px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 12px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        .icon {
          position: absolute;
          right: 12px;
          /* Sesuaikan dengan kebutuhan */
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .options {
        cursor: pointer;
        list-style-type: none;
        padding: 0px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        background: #fff;
        width: 100%;
        bottom: 0px;
        margin-top: 10px;
        z-index: 100;
        height: 180px;
        overflow: auto;
        max-height: 200px;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #c6e3fa;
        }

        li {
          padding: 12px;
        }
      }
    }

    button {
      margin-top: 8px;
      padding: 8px 12px;
      background-color: #24b39b;
      border: none;
      border-radius: 4px;
      color: #fff;
      align-self: flex-end;
      height: 45px;
    }
  }
}

@media (max-width: 480px) {
  .onboarding {
    .cs-avatar.cs-avatar--md {
      width: 20px;
      height: 20px;
      min-width: 35px;
      min-height: 35px;
    }

    .onboarding-chat {
      padding: 4px;
      display: flex;
      flex-direction: column;

      button {
        margin-top: 8px;
        padding: 8px 12px;
        background-color: #24b39b;
        border: none;
        border-radius: 4px;
        color: #fff;
        height: 45px;
        width: auto;
      }
    }

    .onboarding-select {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: space-between;

      .custom-dropdown {
        display: flex;
        flex-direction: column;
        position: relative;

        .select {
          margin-top: 10px;
          position: relative;
          display: inline-block;
          width: 270px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          padding: 12px;
          cursor: pointer;

          .icon {
            position: absolute;
            right: 12px;
            /* Sesuaikan dengan kebutuhan */
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .options {
          cursor: pointer;
          list-style-type: none;
          padding: 0px;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          position: absolute;
          background: #fff;
          width: 100%;
          top: 70px;
          bottom: 0px;
          margin-top: 10px;
          z-index: 100;
          height: 180px;
          overflow: auto;
          max-height: 200px;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            background: #c6e3fa;
          }

          li {
            padding: 12px;
          }
        }
      }

      button {
        margin-top: 2px;
        padding: 8px 12px;
        background-color: #24b39b;
        border: none;
        border-radius: 4px;
        color: #fff;
        height: 45px;
        align-self: normal;
      }
    }
  }
}
