$small: 300px;
$medium: 900px;

.omnichannel {
  height: 85vh;
  font-family: $font-family-sans-serif;

  //some CSS
  @media screen and (max-width: $small) {
    height: 100%;
    overflow: scroll;
  }
  @media screen and (min-width: $medium) {
    //do Smth
  }

  .filter {
    font-family: $font-family-sans-serif !important;
  }

  .cs-sidebar {
    max-width: 320px !important;
  }

  .cs-message-group--outgoing {
    .cs-message__content {
      background-color: #98d9ce !important;
    }
  }

  .cs-message-separator::before {
    display: none;
  }

  .cs-message-separator::after {
    display: none;
  }

  .cs-message-separator {
    color: black;
    align-items: center;
    justify-content: center;
    .group-time {
      background-color: $gray-200;
      width: fit-content;
      border-radius: 4px;
      padding: 4px 8px;
    }

    .admin-takeover {
      background-color: #d5e7f5;
      width: 100%;
      padding: 4px 8px;
    }
  }

  .cs-conversation {
    height: 85px;

    .cs-avatar {
      display: flex;
      height: 100%;
      align-items: center;
      margin-right: 30px;

      img {
        width: 58px;
        height: 58px;
        border-radius: 50%;
      }
    }
  }

  .cs-conversation-header__content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .cs-conversation__content {
    height: 100%;
    justify-content: flex-start;
  }

  .cs-conversation__name {
    font-weight: 600 !important;
    font-family: $font-family-sans-serif !important;
    color: $primary;
    margin-bottom: 4px;
  }

  .cs-conversation__last-activity-time {
    align-items: start;
  }

  .cs-conversation__info {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: wrap;
    text-align: left;

    .cs-conversation__info-content {
      display: unset;
    }
  }

  .cs-conversation__unread {
    font-family: $font-family-sans-serif !important;
    top: 2.5rem;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0.3em 0.5em;
  }

  .admin-flag {
    font-family: $font-family-sans-serif !important;
    font-weight: 400 !important;
    font-size: 10px;
    background-color: $yellow-1000;
    width: fit-content;
    padding-left: 1px;
    padding-right: 1px;
  }

  .close-flag {
    font-family: $font-family-sans-serif !important;
    font-weight: 400 !important;
    font-size: 10px;
    background-color: #ecf2fd;
    width: fit-content;
    padding-left: 1px;
    padding-right: 1px;
  }

  .need-takeover-flag {
    font-family: $font-family-sans-serif !important;
    font-weight: 400 !important;
    font-size: 10px;
    background-color: $red-100;
    width: fit-content;
    padding-left: 1px;
    padding-right: 1px;
  }

  .label {
    text-align: left;
    font-size: medium;
    font-weight: bold;
    margin-bottom: unset;
  }

  .value {
    text-align: left;
    font-size: small;
    font-weight: 400;
  }

  .information-user {
    font-family: $font-family-sans-serif !important;
    background-color: $gray-200;
    height: 100%;
    overflow-y: scroll;

    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 50px;
      gap: 6px;
      background-color: $body-bg;

      p {
        margin-bottom: unset;
      }
    }

    .title {
      font-size: 14px;
      font-weight: bold;
    }

    .label {
      text-align: left;
      font-size: medium;
      font-weight: bold;
      margin-bottom: unset;
    }

    .label-form {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
    }

    .value {
      text-align: left;
      font-size: small;
      font-weight: 400;
    }
  }

  .end-chat-container {
    width: 465px;
    background-color: $gray-200;
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 8px;

    .end-chat-container-title {
      font-size: 14px;
      font-weight: bold;
    }

    .regular-bold {
      font-size: 12px;
      font-weight: bold;
    }

    .regular {
      font-size: 12px;
    }

    .end-chat-container-desc {
      font-size: 14px;
    }

    .end-chat-container-actions {
      display: flex;
      gap: 8px;
      width: 100%;
      justify-content: center;
    }
  }

  .rating-review-container {
    font-family: $font-family-sans-serif;
    width: 465px;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 8px;
    border: 1px solid black;

    .rating-review-title {
      font-size: 14px;
      font-weight: bold;
    }

    .regular-bold {
      font-size: 12px;
      font-weight: bold;
    }

    .regular {
      font-size: 12px;
    }

    .rating-review-desc {
      font-size: 14px;
      text-align: left;
    }

    .rating-review-actions {
      display: flex;
      gap: 8px;
      width: 100%;
      justify-content: center;
    }
  }

  .step-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    gap: 4px;
    width: 100%;
  }

  .step {
    font-size: 12px;
    text-align: left;
    margin-bottom: unset;
  }

  .step-time {
    font-size: 10px;
    text-align: left;
    margin-bottom: unset;
  }

  .sidebar-button {
    cursor: pointer;
  }

  .websocket-status-text {
    font-size: 12px;
    font-family: 'Poppins', sans-serif !important;
    color: white;
  }

  .websocket-status-container {
    width: 100%;
    // display: flex;
    // justify-content: center;
    position: absolute;
    display: block;
    top: -25px;
    width: 100%;
    height: 30px;
    padding: 6px;
    z-index: 99;
    // left: 0;

    &.disconnected {
      background-color: red;
    }

    &.reconnect {
      background-color: black;
    }

    &.connected {
      background-color: #24b39b;
    }
  }
}
