.speechBubble {
  position: fixed;
  bottom: 100px;
  right: 10px;
  font-size: 14px;
  color: black;
  border-radius: 14px;
  background-color: #eafaf7;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.live-chat.embed {
  position: fixed;
  right: 15px;
  bottom: 25px;
  padding: 0;
  background-color: #eafaf7;
  width: 60px;
  border-radius: 50%;
  height: 55px;
  transition: background-color 0.3s ease;
  border: 1px solid #f3f3f3;

  .websocket-status-container {
    font-size: 10px;
    font-family: $font-family-sans-serif;
    color: #fff;
    width: 100%;
    width: 100%;
    padding: 6px;

    &.disconnected {
      background-color: red;
    }

    &.reconnect {
      background-color: #2b5bd8;
    }

    &.connected {
      background-color: #24b39b;
    }
  }

  .end-chat-container {
    font-family: $font-family-sans-serif;
    width: 465px;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 8px;
    border: 1px solid black;

    .end-chat-container-title {
      font-size: 14px;
      font-weight: bold;
    }

    .end-chat-container-desc {
      font-size: 14px;
    }

    .end-chat-container-actions {
      display: flex;
      gap: 8px;
      width: 100%;
      justify-content: center;
    }
  }

  &.open {
    width: 350px;
    height: 550px;
    right: 10px;
    bottom: 10px;
    border: 0;
    border-radius: 10px;

    .card {
      overflow: auto;
      max-height: 550px;
    }
  }

  .card {
    padding: 0;

    .card-body {
      padding: 0;
    }

    .card-header {
      background-color: #fff;
      padding: 15px;
      font-weight: 600;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      align-items: flex-end;
      position: relative;
      border-bottom: 1px solid #f6f6f6;

      .actions {
        position: absolute;
        right: 15px;
        top: 10px;
        height: 30px;
        width: 130px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;

        .close {
          width: 74px;
          font-size: 10px;
          border: 1px solid #29ccb1;
          color: #29ccb1;
          border-radius: 3px;
          padding: 3px 5px;
        }

        .close-primary {
          width: 74px;
          font-size: 10px;
          background-color: #29ccb1;
          color: #fff;
          border-radius: 3px;
          padding: 3px 5px;
        }

        button {
          border: 0;
          background: transparent;
          width: 40px;
          cursor: pointer;
          padding: 0;

          &:hover .tooltiptext {
            visibility: visible;
            opacity: 1;
          }

          .tooltiptext {
            visibility: hidden;
            width: 130px;
            background-color: #555;
            color: #fff;
            text-align: center;
            padding: 5px 0;
            border-radius: 6px;
            right: 0;
            font-size: 12px;
            position: absolute;
            z-index: 1;
            bottom: -40px;
            margin-left: -60px;
            transition: opacity 0.3s;
            // visibility: visible;
            // opacity: 1;
            z-index: 99;
          }
        }
      }

      .title {
        color: #000;
      }

      img,
      svg {
        width: 30px;
        height: 30px;
        margin-right: 15px;
        color: #666;
      }
    }

    .card-footer {
      font-weight: 100;
      font-size: 8px;
      position: absolute;
      bottom: 5px;
      width: 100%;
      color: #000;

      a {
        font-style: normal;
        text-decoration: unset;
      }
    }
  }

  .cs-main-container {
    height: 490px;
    border: 0;

    .cs-chat-container {
      border-radius: 15px;

      .cs-message-list {
        padding-top: 0;
      }
    }
  }

  .liveChatBtn {
    width: 80px;
    height: 80px;
    font-size: 14px;
    color: white;
    border: none;
    display: block;
    padding: 0px;
    display: flex;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    /* Remove default border */
    cursor: pointer;
    /* Change cursor on hover */
    /* Smooth transition for hover effect */

    svg {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}

.close {
  width: 74px;
  font-size: 10px;
  border: 1px solid #29ccb1;
  color: #29ccb1;
  background-color: #ffff;
  border-radius: 3px;
  padding: 3px 5px;
}

.close-primary {
  width: 74px;
  font-size: 10px;
  background-color: #29ccb1;
  border: unset;
  color: #fff;
  border-radius: 3px;
  padding: 3px 5px;
}
