.chat-loader-page {
    background-color: #ececec;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    color: #cbcbcb;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: space-around;
    align-items: center;

    .chat-loader-wrapper {
        border: 16px solid #f3f3f3;
        /* Light grey */
        border-top: 16px solid #1CAC93;
        /* Blue */
        border-radius: 50%;
        width: 40px;
        height: 40px;
        -webkit-animation: spin 2s linear infinite;
        /* Safari */
        animation: spin 2s linear infinite;
        margin: auto;
        /* Center the loader */


        /* Safari */
        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
}