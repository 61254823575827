.register-form {
    display: block;
    width: 100%;

    form {
        // background-color: rgb(245, 245, 245);
    }

    .welcome {
        color: #666666;
        font-size: 12px;
    }

    fieldset {
        display: flex;
        padding: 10px 0 0;
        border: none;
        border-radius: 4px;
        flex-direction: column;
    }

    label {
        margin-bottom: 8px;
        font-size: 14px;
        color: #555;
        text-align: left;
    }

    sup {
        color: red;
        top: -2px;
        font-size: 95%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }

    input,
    select {
        border-radius: 4px;
        border: 1px solid #ccc;
        height: 32px;
        padding: 0 8px;
    }

    .Field {
        position: relative;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
    }

    .FieldError {
        position: absolute;
        bottom: -6px;
        color: red;
        font-size: 12px;
    }

    .Row {
        display: flex;
        flex-direction: row;
    }

    button {
        padding: 12px;
        width: 190px;
        border-radius: 4px;
        border: 1px solid #ccc;
        background-color: #46b896;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
    }

    textarea {
        height: 70px;
        border: 1px solid #cacaca;
        border-radius: 3px;
        padding: 5px;
    }

    button:disabled,
    button[disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
    }

    .card-footer {
        background-color: #6b89ac;
    }

    .footer {
        display: flex;
        padding: 10px;
        align-items: baseline;
        justify-content: space-between;
    }

    p.error-message {
        color: red;
        font-size: 10px;
        text-align: left;
    }
}